import Banner from './../img/convenios/provenza.jpg'



export default function ImageModule(){
    return(
        <div  className="me-12 d- mt-4 text-center"  >         
          <p >
          <img className='d-block w-100' src={Banner} alt='Mensajero' />    
          </p>
          
        </div>
        
    )
}