import Carousel from 'react-bootstrap/Carousel';
export default function ProvenzaContenido() {

    return (
        <div className="w-75 mx-auto pt-5" style={{ textAlign: 'justify' }}>
            <h4><span ><strong>Convenio con Provenza</strong></span></h4>
            <p >Por hacer parte de la familia Coopmulmag, nuestros afiliados recibirán un bono con el
                cual podrán asistir a Provenza Restaurante - Bailadero. y recibir muchos beneficios como lo son:
            </p>
            <hr />
              <p>
                <ul>
                <li>Descuento del 10 % en comida y bebidas. </li>
                <li>Obsequios especiales el día de su cumpleaños. </li>
                <li><b>Transferible:</b> tus amigos y/o familiares pueden hacer uso de este bono.</li>
                <li><b>Reutilizable:</b> El mismo bono se puede usar las veces que se visite Provenza.</li>
                </ul>
            </p>

            

        </div>
    )
}